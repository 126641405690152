






















import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'

function getPages(count, current, total) {
  let arr = [] as Array<number>
  const iteration = Math.floor(count / 2)

  if (total >= count) {
    arr.push(current)

    for (let i = 1, len = iteration; i <= len; i++) {
      arr.unshift(current - i)
      arr.push(current + i)
    }

    if (arr[0] < 1) {
      arr = arr.map(c => c + (1 - arr[0]))
    }

    if (arr[arr.length - 1] > total) {
      arr = arr.map(c => c - (arr[arr.length - 1] - total))
    }

    arr[0] = 1
    arr[arr.length - 1] = total
  } else {
    arr = Array.from([...Array(total).keys()].map(x => x + 1))
  }

  return arr
}

export default defineComponent({
  props: {
    current: {
      type: Number,
      default: 1,
    },
    count: {
      type: Number,
      default: 3,
      validator: v => v >= 3,
    },
    total: {
      type: Number,
      default: 2,
    },
    onChange: {
      type: Function,
      default: v => {
        console.log(v)
      },
    },
  },
  setup(props) {
    const pages = ref([] as number[])
    const xx = ref<HTMLElement | null>(null)

    onMounted(() => {
      xx.value = document.querySelector('.content-search')
    })

    const select = p => {
      if (xx.value) {
        window.scrollTo({
          top: xx.value.offsetTop - 70,
          behavior: 'smooth',
        })

        console.log('xx.value.offsetTop', xx.value.offsetTop)
      }

      props.onChange(p)
    }

    watch(
      () => [props.current, props.total],
      () => {
        const { count, current, total } = props
        pages.value = getPages(count, current, total)
      }
    )

    return {
      pages,
      select,
    }
  },
})
