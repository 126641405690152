























































































































import { defineComponent } from '@vue/composition-api'
import { VueSrcData } from '@/inc/directives/src'

interface MemberCard {
  title: string
  sector: string
  country: string
  picture?: VueSrcData
}

export default defineComponent({
  props: {
    content: {
      type: Object as () => MemberCard,
      default: () => ({} as MemberCard),
    },
  },
})
