















































































































































import { defineComponent } from '@vue/composition-api'
import { VueSrcData } from '@/inc/directives/src'
import { mapActions } from '@/store/utils'

interface SpeakerCard {
  name: string
  lastname: string
  firstname: string
  jobTitle: string
  department: string
  title: string
  email: {
    value: string
    label: string
  }
  country: {
    value: string
    label: string
  }
  city: string
  phone: {
    value: string
    label: string
  }
  picture?: VueSrcData
}

export default defineComponent({
  props: {
    content: {
      type: Object as () => SpeakerCard,
      default: () => ({} as SpeakerCard),
    },
    tag: {
      type: String,
      default: () => 'div',
    },
  },

  setup(props, ctx) {
    const { addPopupContent } = mapActions(ctx, 'popup', ['addPopupContent'])
    const openPopup = content => {
      console.log('content card', content)
      const card = {
        popup: {
          name: content.name,
          lastname: content.lastname,
          firstname: content.firstname,
          picture: content.picture,
          category: 'Speaker',
          jobTitle: content.jobTitle,
          department: content.department,
          country: content.country,
          email: content.email,
          biography: content.biography,
          about: content.about,
          socialNetwork: content.socialNetwork,
        },
      }
      addPopupContent(card)
    }

    return {
      openPopup,
    }
  },
})
