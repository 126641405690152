





















































































// GridCards.vue.ts
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SimpleCardGrid',
  props: {
    content: Array,
  },
})
