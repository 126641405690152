























































import { computed, defineComponent, reactive } from '@vue/composition-api'
import SimpleCardGrid from '@/components/SimpleCardGrid.vue'
import MembersCard from '@/components/members/Card.vue'
import SpeakersCard from '@/components/speakers/Card.vue'
import ContentFilters from '@/components/content/Filters.vue'
import ContentPagination from '@/components/content/Pagination.vue'

export default defineComponent({
  props: {
    content: Object,
    type: String,
  },
  setup(props) {
    const state = reactive({
      page: 1,
      perPage: 12,
      filters: {
        sector: null,
        country: null,
        topics: null,
        year: null,
        name: '',
      },
    })

    const filteredGrid = computed(() => {
      const { filters } = state
      let grid = props.content?.grid

      if (filters.country) {
        grid = grid.filter(c => c.country)
        grid = grid.filter(
          c =>
            (c.country.value ? c.country.value : c.country) === filters.country
        )
      }

      if (filters.sector) {
        grid = grid.filter(c => c.sector.value === filters.sector)
      }

      if (filters.topics) {
        grid = grid.filter(c => c.topic.value === filters.topics)
      }

      if (filters.year) {
        grid = grid.filter(c => c.year === filters.year)
      }

      if (filters.name) {
        grid = grid.filter(c => {
          const re = filters.name.split('').join('.*')
          const regex = new RegExp(`.*${re}.*`, 'gi')

          return props.type === 'speakers'
            ? regex.test(c.name)
            : regex.test(c.title)
        })
      }

      return grid
    })

    const displayedGrid = computed(() => {
      const { page, perPage } = state
      const start = (page - 1) * perPage
      const end = start + perPage

      return filteredGrid.value.slice(start, end)
    })

    const pagesTotal = computed(() =>
      Math.ceil(filteredGrid.value.length / state.perPage)
    )

    function changeFilter(name, value) {
      state.filters[name] = value
      state.page = 1
    }

    function changePage(i) {
      state.page = i
    }

    return {
      changeFilter,
      changePage,
      displayedGrid,
      filteredGrid,
      pagesTotal,
      state,
    }
  },
  components: {
    SimpleCardGrid,
    MembersCard,
    SpeakersCard,
    ContentFilters,
    ContentPagination,
  },
})
