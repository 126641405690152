






























import { onMounted, defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    content: Array,
    type: String,
    onChange: {
      type: Function,
      default: (n, v) => {
        console.log(n, v)
      },
    },
  },
  setup(props) {
    onMounted(() => {
      props.content.sort((a, b) => {
        const fa = a.name.toLowerCase()
        const fb = b.name.toLowerCase()

        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }

        return 0
      })
    })
  },
})
